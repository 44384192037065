




























































































import { Vue, Component } from 'vue-property-decorator'
import { FooterContentDto, StrapiApi } from '~/apis'
import { FromPage } from '~/types'
import NotifyEmailInput from '~/components/NotifyEmailInput.vue'

@Component({
  components: {
    NotifyEmailInput
  }
})
export default class Footer extends Vue {
  footerContent: FooterContentDto = {
    featuresList: [],
    logo: '',
    socialIcons: [],
    venueInfo: {}
  }

  mounted () {
    this.getFooterIcons()
  }

  async getFooterIcons () {
    const schoolSlug = this.$route.params.schoolSlug || ''
    const { data: { data } } = await new StrapiApi().getFooterContent(schoolSlug)
    this.footerContent = data

    if (this.isAtSchoolPage) {
      const legalFeatureIndex = this.footerContent.featuresList.findIndex(o => o.name === 'Legal')
      if (typeof legalFeatureIndex !== 'undefined') {
        const routes = this.footerContent.featuresList[legalFeatureIndex].routers.filter(o => o.path !== '/course-policy')

        this.footerContent.featuresList[legalFeatureIndex].routers = routes
      }
    }
  }

  get isAtSchoolPage () {
    return this.$store.state.currentViewFor === FromPage.AtSchool
  }

  get venueEmail () {
    if (this.$store.state.venueEmail) {
      return this.$store.state.venueEmail
    }
    return this.footerContent?.venueInfo?.venueEmail || ''
  }
}
