






































































import { Component, Vue } from 'vue-property-decorator'
import { Prop } from 'nuxt-property-decorator'

@Component
export default class AttendSession extends Vue {
  @Prop({ default: () => '' }) title: string | undefined
  @Prop({ default: () => '' }) message: string | undefined
  @Prop({ default: () => 'Confirm' }) okButton: string | undefined
  @Prop({ default: () => 'No' }) cancelButton: string | undefined
  @Prop({ default: () => true }) isConfirm: boolean | undefined
  @Prop({ default: () => true }) isShowCancelButton: boolean | undefined
  @Prop({ default: () => true }) isShowConfirmButton: boolean | undefined
  @Prop({ default: () => true }) isShowActionButtons: boolean | undefined

  close () {
    this.$emit('cancelAction')
  }

  ok () {
    this.$emit('okAction')
  }

  onClickCloseButton () {
    this.$emit('onClickCloseButton')
  }
}
