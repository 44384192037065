



































import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import { MenuDto } from '~/types'

@Component({
  components: {}
})

export default class FlyoutMenu extends Vue {
  @Prop({ type: String }) buttonName!:string;
  @Prop({ type: [] }) menu!: MenuDto[];
  @Prop({ type: Boolean }) isOpen: boolean = false;
  @Prop({ type: String }) textColor!: string

  @Ref('flyoutMenu') readonly myMenu!: HTMLDivElement

  outsideClickListener: null | any = null

  toggleFlyoutMenu () {
    this.$emit('toggleFlyoutMenu')
  }

  onMouseOver () {
    this.$emit('onMouseOver')
  }

  onMouseLeave () {
    this.$emit('onMouseLeave')
  }
}
