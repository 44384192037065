




















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CampaignsApi, StrapiApi, ShowcaseCampaignPanelDto } from '~/apis'

@Component({})
export default class YellowPanel extends Vue {
    @Prop() pageToShow!: string
    @Prop() afterLoginRedirect!: string
    @Prop() beforeLoginRedirect!: string

    earnedHour: number = 0
    campaignShowcasePanel = {} as ShowcaseCampaignPanelDto

    mounted () {
      // this.getCampaignData()
      new StrapiApi().getCampaignShowCasePanel()
        .then((val:any) => {
          const { data: { data } } = val
          this.campaignShowcasePanel = data
        })
    }

    get isLoggedIn () {
      return this.$store.state.authenticated
    }

    // getCampaignData () {
    //   const loading = this.$loading.show()
    //   new CampaignsApi().getGiveAndGetCampaign()
    //     .then((val) => {
    //       this.earnedHour = val.data.data.earnedHour || 0
    //     })
    //     .finally(() => loading.hide())
    // }
}
