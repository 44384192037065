









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class BadgeIcon extends Vue {
  @Prop({ type: Number, default: 0 }) numberDisplay!:number
}
