import { render, staticRenderFns } from "./NotifyEmail.vue?vue&type=template&id=5fde59ec&scoped=true&"
import script from "./NotifyEmail.vue?vue&type=script&lang=ts&"
export * from "./NotifyEmail.vue?vue&type=script&lang=ts&"
import style0 from "./NotifyEmail.vue?vue&type=style&index=0&id=5fde59ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fde59ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotifyEmailInput: require('/var/www/dockerize-nuxt/agora-app/components/NotifyEmailInput.vue').default})
