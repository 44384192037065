

















































import { CheckCircleIcon } from '@vue-hero-icons/solid'
import { cloneDeep } from 'lodash-es'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import NotifyEmailInput from '~/components/NotifyEmailInput.vue'
import { sleep } from '~/utils/functions'

@Component({
  components: {
    CheckCircleIcon,
    NotifyEmailInput
  }
})
export default class NotifyEmail extends Vue {
  @Prop({ type: String, default: '#fff' }) colorTitle! :string
  @Prop({ type: String, default: '#fff' }) colorTitleEmailInput! :string
  @Prop({ type: String, default: '#fafafa' }) colorItem! :string

  @Prop({ type: String, default: '' }) title! :string
  @Prop({ type: String, default: '' }) image! :string
  @Prop({ type: Array, default: () => [] }) reasons! :any[]

  @Prop({ type: String, default: '' }) epTitle! :string
  @Prop({ type: String, default: '' }) epDesc! :string

  @Prop({ type: Boolean, default: true }) isShow! :boolean
  @Prop({ type: Boolean, default: false }) isShowImage! :boolean
  @Prop({ type: Boolean, default: false }) isShowNotify! :boolean
  @Prop({ type: Boolean, default: true }) isShowForm! :boolean

  get autheticated () {
    return this.$store.state.authenticated
  }

  mounted () {
    if (process.client) {
      const elementContactUs = document.getElementById('contact-us-embedded-form')
      if (elementContactUs?.children.length === 0) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '22358917',
          formId: '5a5288fb-1fd2-476c-b499-fa6bbb1ceabe',
          target: '#contact-us-embedded-form'
        })
        this.changeValueLeadFormPage()
      }
    }
  }

  @Watch('$route', { deep: true })
  async changeValueLeadFormPage () {
    let count = 0
    while (true && count < 5) {
      count++
      const leadFormPageElement = document.querySelector('#contact-us-embedded-form .input [name=lead_form_page]') as HTMLElement
      await sleep(500)
      if (leadFormPageElement && cloneDeep(leadFormPageElement?.tagName)) {
        leadFormPageElement.setAttribute('value', this.$route.fullPath)
        break
      }
      this.changeValueLeadFormPage()
    }
  }
}
