
export default {
  name: 'MyLoading',
  data () {
    return {
      loadingComponents: [],
      manual: true
    }
  },
  methods: {
    start () {
      this.loadingComponents.push(this.$loading.show())
    },
    finish () {
      setTimeout(() => {
        this.$nextTick(() => {
          this.loadingComponents.forEach(val => val.hide())
        })
      }, 500)
    },
    fail () {
      setTimeout(() => {
        this.$nextTick(() => {
          this.loadingComponents.forEach(val => val.hide())
        })
      }, 500)
    }
  },
  render (h) {
    let el = h(false)
    el = h('div')
    return el
  }
}
