






























































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { MenuIcon, XIcon, UserIcon } from '@vue-hero-icons/solid'
import { BellIcon } from '@vue-hero-icons/outline'
import { ProvidersApi, CompaniesApi } from '~/apis'
import BadgeIcon from '~/components/BadgeIcon.vue'
import { FromPage } from '~/types'

@Component({
  components: {
    BellIcon, MenuIcon, XIcon, UserIcon, BadgeIcon
  }
})
export default class Header extends Vue {
  show = false
  showMobile = false
  showContact = false
  outsideClickListener: null | any = null

  @Ref('myMenu') readonly myMenu!: HTMLDivElement
  @Ref('myContact') readonly myContact!: HTMLDivElement
  now = Date.now()
  providerImage = ''
  emailCompany = ''
  phoneCompany =''

  get authenticated () {
    return this.$store.state.authenticated
  }

  get avatar () {
    return this.$store.state.user?.avatar
  }

  get badgeItems () {
    return this.$store.state.shoppingCart?.badgeItems
  }

  /**
   *  fullPath: "/education-provider"
      hash: ""
      meta: {}
      name: "education-provider"
      params: {}
      path: "/education-provider"
      query: {}

    fullPath: "/"
      hash: ""
      meta: {}
      name: null
      params: {}
      path: "/"
      query: {}
 */
  /**
   *
    fullPath: "/education-provider/schedule/list"
    hash: ""
    meta: {}
    name: "education-provider-schedule-list"
    params: {}
    path: "/education-provider/schedule/list"
    query: {}
 */
  async signOut () {
    const currentUser = this.$store.state.user?.sub || ''
    this.$store.commit('setAuthentication', { authenticated: false, user: null })
    await this.$auth.logout();
    (window as any).analytics.track('Signed Out');
    (window as any).analytics.identify(currentUser, {
      age: '0'
    })
    if (this.$store.state.currentViewFor === FromPage.AtSchool) {
      this.$router.push('/sign-in')
    } else {
      this.$router.push('/')
    }
  }

  async mounted () {
    this.bindOutsideClickListener()
    this.contactDetails()
    if (this.authenticated) {
      const { data } = (await new ProvidersApi().getProviderInformation()).data
      this.providerImage = data.providerImage || ''
    }
  }

  async contactDetails () {
    const response = await new CompaniesApi().getCompany()
    if (response?.data?.data?.email) {
      this.emailCompany = response?.data.data.email
    }
    if (response?.data?.data?.contactNumber) {
      this.phoneCompany = response?.data.data.contactNumber
    }
  }

  bindOutsideClickListener () {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event: any) => {
        const className: string = event.target.className
        if (this.myMenu && this.show && !className.includes('my-img-user') && !className.includes('contact-details')) {
          this.show = false
          this.unbindOutsideClickListener()
        }
        if (this.myContact && this.showContact && !className.includes('my-img-user') && !className.includes('contact-details')) {
          this.showContact = false
          this.unbindOutsideClickListener()
        }
      }
      document.addEventListener('click', this.outsideClickListener)
    }
  }

  unbindOutsideClickListener () {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener)
      this.outsideClickListener = null
    }
  }

  get isAtSchoolPage () {
    return this.$store.state.currentViewFor === FromPage.AtSchool
  }
}
