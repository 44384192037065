




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { IntercomApi } from '~/apis'
import { showToast } from '~/utils/functions'

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class NotifyEmailInput extends Vue {
  email:string = ''
  @Prop({ type: String, default: '#171717' }) titleColor!:string
  @Prop({ type: String, default: '' }) title!:string
  @Prop({ type: String, default: '' }) titleCustomClass!:string
  @Prop({ type: String, default: '#3851e5' }) btnColor!:string
  @Prop({ type: String, default: '#fff' }) btnTextColor!:string

  @Prop({ type: String, default: '' }) twClass!:string
  @Prop({ default: 'Notify me' }) chineseContentButton!:string
  @Prop({ default: 'Enter your email' }) chinesePlaceholder!:string
  @Prop() chineseError!:string
  @Prop() isChinesePage!:boolean

  checkError (param: string) {
    let result = param
    if (this.isChinesePage && param) {
      result = this.chineseError
    }
    return result
  }

  async subscribeEmail () {
    if (!this.email) {
      return
    }
    const loading = this.$loading.show()
    try {
      const response = await new IntercomApi().subscribeEmailToLead({
        email: this.email
      } as any)
      if (response.status === 200 || response.status === 201) {
        if (!this.isChinesePage) {
          showToast({ text: 'Thank you for subscribing!' })
        } else {
          showToast({ title: '订阅成功', text: '谢谢!' })
        }
        this.email = ''
      }
    } catch (error) {
      showToast({ title: 'Error', text: 'There was an issue subscribing the email!', icon: 'error' })
    } finally {
      loading.hide()
    }
  }
}
